import { render, staticRenderFns } from "./ServiceFormSection.vue?vue&type=template&id=6d39da75&scoped=true"
import script from "./ServiceFormSection.vue?vue&type=script&setup=true&lang=ts"
export * from "./ServiceFormSection.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./ServiceFormSection.vue?vue&type=style&index=0&id=6d39da75&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../../work/modules/node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d39da75",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UICardBordered: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/LORGAR/LORGAR_Deploy_Prod/components/ui/UICardBordered.vue').default,UIModalHeader: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/LORGAR/LORGAR_Deploy_Prod/components/ui/UIModalHeader.vue').default,ServiceForm: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/LORGAR/LORGAR_Deploy_Prod/components/shared/ServiceForm.vue').default,UIModalContent: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/LORGAR/LORGAR_Deploy_Prod/components/ui/UIModalContent.vue').default,ServiceFormSectionModal: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/LORGAR/LORGAR_Deploy_Prod/components/shared/ServiceFormSectionModal.vue').default,ServiceFormSectionModalMessage: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/LORGAR/LORGAR_Deploy_Prod/components/shared/ServiceFormSectionModalMessage.vue').default})
