import { render, staticRenderFns } from "./ServiceFormSectionModal.vue?vue&type=template&id=6ed3a51c"
import script from "./ServiceFormSectionModal.vue?vue&type=script&lang=js"
export * from "./ServiceFormSectionModal.vue?vue&type=script&lang=js"
import style0 from "./ServiceFormSectionModal.vue?vue&type=style&index=0&id=6ed3a51c&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../../../work/modules/node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UIIcon: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/LORGAR/LORGAR_Deploy_Prod/components/ui/UIIcon.vue').default})
